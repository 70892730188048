import FocusTrap from "focus-trap-react";
import { useState } from "react";
import WithClickOutside from "../../components/misc/WithClickOutside";
import { IconTextField } from "../../nessie";
import { CalendarIcon } from "../../nessie/icons";
import { translate } from "../../utils/translate";
import Calendar from "./Calendar";
import { format as formatDate, type DateFormatOptions } from "@web-monorepo/dates";

interface DatePickerProps {
  onChange: (v: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  calendarZIndex?: number;
  externalValidation?: boolean;
  date: Date | null;
  format?: DateFormatOptions;
  dataName?: string;
  "aria-label": string;
  position?: "top" | "bottom";
}

const getDateOrNull = (dateOrMoment: Date | null | undefined) => (dateOrMoment ? dateOrMoment : null);

function DatePicker({
  externalValidation = true,
  date,
  minDate = new Date(),
  maxDate,
  calendarZIndex = 2,
  position = "bottom",
  dataName,
  format = { month: "2-digit", day: "2-digit", year: "numeric" },
  "aria-label": ariaLabel,
  onChange,
}: DatePickerProps) {
  const [showCalendar, setShowCalendar] = useState(false);
  const positionConfigs = position === "top" ? { bottom: "110%" } : { top: "110%" };

  const handleCalendarClick = () => {
    if (showCalendar) return;
    setShowCalendar(true);
  };

  const handleCalendarKeyPress = (event: React.KeyboardEvent<HTMLElement>) => {
    event.stopPropagation();

    if (event.key === "Enter" && !showCalendar) setShowCalendar(true);
  };

  const handleCalendarChange = (date: Date) => {
    setShowCalendar(false);
    onChange(date);
  };
  return (
    <WithClickOutside onClickOutside={() => setShowCalendar(false)}>
      <div sx={{ position: "relative" }}>
        <IconTextField
          value={date ? formatDate(date, /* TODO: Update this moment format string: */ format) : ""}
          valid={externalValidation}
          data-name={`${dataName}_input`}
          aria-label={ariaLabel}
          onClick={handleCalendarClick}
          onKeyDown={handleCalendarKeyPress}
          readOnly
          role="combobox"
          aria-expanded={showCalendar}
          aria-haspopup="dialog"
          aria-controls="DataPickerDialog"
          placeholder="Enter date" // TODO: translate
          leftIcon={<CalendarIcon />}
        />
        {showCalendar && (
          <FocusTrap
            focusTrapOptions={{
              allowOutsideClick: true,
              escapeDeactivates: () => {
                setShowCalendar(false);
                return true;
              },
            }}
            active={showCalendar}
          >
            <div
              role="dialog"
              sx={{ zIndex: calendarZIndex, position: "absolute", left: 0, ...positionConfigs }}
              data-name={`${dataName}_calendar`}
              id="DataPickerDialog"
              aria-label={translate({ str: "dojo.common:date_picker.choose_date", fallback: "Choose date" })}
              aria-modal="true"
            >
              <Calendar
                onChange={handleCalendarChange}
                value={getDateOrNull(date)}
                minDate={minDate}
                maxDate={getDateOrNull(maxDate) ?? undefined}
              />
            </div>
          </FocusTrap>
        )}
      </div>
    </WithClickOutside>
  );
}

export default DatePicker;
